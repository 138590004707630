














import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class CardButton extends Vue {
  // Properties
  @Prop(String)
  icon: string | undefined
  @Prop(String)
  type: string | undefined
  @Prop({ type: Boolean, default: false })
  isLoading!: boolean
}
