






















































































































































































































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import { showInfoNotification } from '@/utils/notification'
import InputFieldWithValidation from '@/components/Eterian/form/InputFieldWithValidation.vue'
import SelectFieldWithValidation from '@/components/Eterian/form/SelectFieldWithValidation.vue'
import DatePickerWithValidation from '@/components/Eterian/form/DatePickerWithValidation.vue'
import autocompleteApi from '@/utils/autocomplete-api'
import { UserStore } from '@/store/modules/user'

@Component({
  components: {
    ValidationObserver,
    InputFieldWithValidation,
    SelectFieldWithValidation,
    DatePickerWithValidation,
  },
})
export default class CompanyForm extends Vue {
  $refs!: {
    form: InstanceType<typeof ValidationObserver>
  }

  // Properties
  @Prop({ type: Boolean, default: false })
  isUpdateForm!: boolean
  @Prop({ type: Boolean, default: false })
  isFirstUpdateForm!: boolean
  @Prop({ type: Boolean, default: false })
  isSudoUpdateForm!: boolean
  @PropSync('formData', { type: Object, required: true })
  form!: Record<string, any>

  // Data
  countryArray = new Array(225)
  cityArray = new Array(10243)
  provinceArray = new Array(116)
  confirmPassword = ''

  // Computed Data
  get isCreateForm() {
    return !(this.isUpdateForm || this.isFirstUpdateForm || this.isSudoUpdateForm)
  }
  get companyProfile() {
    if (this.isUpdateForm) return UserStore.companyProfile
    return undefined
  }
  get submitBtnText() {
    if (this.isCreateForm) return 'Inserisci'
    else return 'Modifica'
  }

  // Vue Hooks
  mounted() {
    this.populateCountryArray()
    this.populateCityArray()
    this.populateProvinceArray()
  }

  // Methods
  async populateCountryArray() {
    const response = await autocompleteApi.getCountries()
    if (response) {
      this.countryArray = response.data
    }
  }
  async populateCityArray() {
    const response = await autocompleteApi.getCities()
    if (response) {
      this.cityArray = response.data
    }
  }
  async populateProvinceArray() {
    const response = await autocompleteApi.getProvinces()
    if (response) {
      this.provinceArray = response.data
    }
  }
  async onSubmit() {
    this.$emit('submit')
  }
  onReset() {
    // Reset form validator on next tick because changes are still propagating
    this.$nextTick(() => {
      this.$refs.form.reset()
    })
    showInfoNotification({ content: 'Campi azzerati' })
    this.$emit('reset')
  }
  onCancel() {
    this.$emit('cancel')
  }
}
