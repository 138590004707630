var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"tile is-ancestor is-block-tablet is-flex-widescreen"},[_c('div',{staticClass:"tile is-parent"},[_c('div',{staticClass:"tile is-child"},[_c('h2',{staticClass:"title is-4 has-text-right mt-2"},[_vm._v("Dati Aziendali")]),_c('input-field-with-validation',{attrs:{"name":"name","label":"Nome","rules":{ required: _vm.form.business_name == undefined || _vm.form.business_name == '' },"title-case":"","autofocus":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('input-field-with-validation',{attrs:{"name":"surname","label":"Cognome","rules":{ required: _vm.form.business_name == undefined || _vm.form.business_name == '' },"title-case":""},model:{value:(_vm.form.surname),callback:function ($$v) {_vm.$set(_vm.form, "surname", $$v)},expression:"form.surname"}}),_c('input-field-with-validation',{attrs:{"name":"business_name","label":"Ragione Sociale","rules":{
              required: _vm.form.name == undefined || _vm.form.name == '' || _vm.form.surname == undefined || _vm.form.surname == '',
            }},model:{value:(_vm.form.business_name),callback:function ($$v) {_vm.$set(_vm.form, "business_name", $$v)},expression:"form.business_name"}}),_c('input-field-with-validation',{attrs:{"name":"address","label":"Indirizzo","rules":"required","first-letter-case":""},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('input-field-with-validation',{attrs:{"name":"city","label":"Città","autocomplete-data":_vm.cityArray,"rules":"required","first-letter-case":""},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}}),_c('input-field-with-validation',{attrs:{"name":"province","label":"Provincia","autocomplete-data":_vm.provinceArray,"rules":{
              required: true,
              min: 2,
              max: 2,
              alpha: true,
              oneOf: _vm.provinceArray,
            },"upper-case":""},model:{value:(_vm.form.province),callback:function ($$v) {_vm.$set(_vm.form, "province", $$v)},expression:"form.province"}}),_c('input-field-with-validation',{attrs:{"name":"zip_code","label":"CAP","rules":"required|digits:5"},model:{value:(_vm.form.zip_code),callback:function ($$v) {_vm.$set(_vm.form, "zip_code", $$v)},expression:"form.zip_code"}}),_c('input-field-with-validation',{attrs:{"name":"country","label":"Nazione","autocomplete-data":_vm.countryArray,"rules":{ required: true, oneOf: _vm.countryArray }},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}}),(!_vm.isUpdateForm)?_c('input-field-with-validation',{attrs:{"name":"vat_number","label":"Partita IVA","rules":"required|vat_number","upper-case":""},model:{value:(_vm.form.vat_number),callback:function ($$v) {_vm.$set(_vm.form, "vat_number", $$v)},expression:"form.vat_number"}}):(_vm.companyProfile)?_c('b-field',{staticClass:"ric-form-read",attrs:{"label":"Partita IVA","horizontal":""}},[_c('b-input',{attrs:{"value":_vm.companyProfile.vat_number,"readonly":""}})],1):_vm._e(),(!_vm.isUpdateForm)?_c('input-field-with-validation',{attrs:{"name":"fiscal_code","label":"Codice Fiscale","rules":"fiscal_code","upper-case":""},model:{value:(_vm.form.fiscal_code),callback:function ($$v) {_vm.$set(_vm.form, "fiscal_code", $$v)},expression:"form.fiscal_code"}}):(_vm.companyProfile)?_c('b-field',{staticClass:"ric-form-read",attrs:{"label":"Codice Fiscale","horizontal":""}},[_c('b-input',{attrs:{"value":_vm.companyProfile.fiscal_code,"readonly":""}})],1):_vm._e()],1)]),_c('div',{staticClass:"tile is-vertical is-parent"},[_c('div',{staticClass:"tile is-child"},[_c('span',{staticClass:"mt-5 is-block-widescreen"}),_c('h2',{staticClass:"title is-4 has-text-right mt-2"},[_vm._v("Fatturazione Elettronica")]),_c('input-field-with-validation',{attrs:{"name":"sdi","label":"SDI","rules":{
              required: _vm.form.pec == undefined || _vm.form.pec == '',
              alpha_num: true,
              min: 7,
              max: 7,
            },"upper-case":""},model:{value:(_vm.form.sdi),callback:function ($$v) {_vm.$set(_vm.form, "sdi", $$v)},expression:"form.sdi"}}),_c('input-field-with-validation',{attrs:{"name":"pec","label":"PEC","type":"email","rules":{
              required: _vm.form.sdi == undefined || _vm.form.sdi == '',
              email: true,
            }},model:{value:(_vm.form.pec),callback:function ($$v) {_vm.$set(_vm.form, "pec", $$v)},expression:"form.pec"}})],1),_c('div',{staticClass:"tile is-child"},[_c('h2',{staticClass:"title is-4 has-text-right mt-2"},[_vm._v("Contatti")]),(_vm.isCreateForm)?_c('input-field-with-validation',{attrs:{"name":"email","label":"E-mail","type":"email","rules":"required|email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}):_vm._e(),_c('input-field-with-validation',{attrs:{"name":"phone_number","label":"Telefono Fisso","rules":{
              required: _vm.form.mobile_number == undefined || _vm.form.mobile_number == '',
              numeric: true,
            }},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}}),_c('input-field-with-validation',{attrs:{"name":"mobile_number","label":"Cellulare","rules":{
              required: _vm.form.phone_number == undefined || _vm.form.phone_number == '',
              numeric: true,
            }},model:{value:(_vm.form.mobile_number),callback:function ($$v) {_vm.$set(_vm.form, "mobile_number", $$v)},expression:"form.mobile_number"}})],1),(_vm.isCreateForm)?_c('div',{staticClass:"tile is-child"},[_c('span',{staticClass:"mt-5 is-block-widescreen"}),_c('h2',{staticClass:"title is-4 has-text-right mt-2"},[_vm._v("Autenticazione")]),_c('input-field-with-validation',{attrs:{"name":"password","label":"Password","type":"password","rules":"required|secure_password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('input-field-with-validation',{attrs:{"name":"confirm_password","label":"Conferma Password","type":"password","rules":"required|confirmed:password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1):_vm._e()])]),_c('hr'),_c('div',{staticClass:"is-width-50-widescreen"},[_c('b-field',{attrs:{"horizontal":""}},[_c('b-field',{attrs:{"grouped":""}},[_c('div',{staticClass:"control"},[_c('b-button',{attrs:{"native-type":"submit","type":"is-primary"}},[_vm._v(_vm._s(_vm.submitBtnText))])],1),_c('div',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-danger"},on:{"click":_vm.onCancel}},[_vm._v("Annulla")])],1),(_vm.isCreateForm)?_c('div',{staticClass:"control"},[_c('b-button',{attrs:{"native-type":"reset","type":"is-info is-outlined"},on:{"click":function($event){$event.preventDefault();return _vm.onReset.apply(null, arguments)}}},[_vm._v("Azzera")])],1):_vm._e()])],1)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }