import { BaseUtils } from './base'

export interface IUserProfile {
  full_name: string
  email: string
  must_change_password: boolean
  is_active: boolean
  is_admin: boolean
  is_superuser: boolean
  id: number
  company_id: number
  created_at: string
  updated_at: string
}

export interface IUserProfileUpdate {
  full_name?: string
  password?: string
  is_active?: boolean
  // TODO: check if we need to add must_change_password field
}

export interface IUserPasswordUpdate {
  current_password: string
  new_password: string
}

export interface IUserProfileCreate {
  full_name: string
  email: string
  password: string
  must_change_password?: boolean
  is_active?: boolean
}

class UserProfileUtilsImpl extends BaseUtils {
  createInit(): IUserProfileCreate {
    return {
      full_name: '',
      email: '',
      password: '',
      must_change_password: undefined,
      is_active: undefined,
    }
  }

  updateInit(obj: IUserProfile | null): IUserProfileUpdate {
    if (obj)
      return {
        full_name: obj.full_name,
        password: undefined,
        is_active: obj.is_active,
      }
    return {}
  }
}

export const UserProfileUtils = new UserProfileUtilsImpl()
