









import { AppStore } from '@/store/modules/app'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class DynamicSection extends Vue {
  // Properties
  @Prop({ type: Boolean, default: false })
  isMainSection!: boolean
  @Prop({ type: Boolean, default: false })
  isSlim!: boolean

  // Computed Data
  get isContainerFluid() {
    return AppStore.isContainerFluid
  }
}
