
































import { Vue, Component, Prop } from 'vue-property-decorator'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

export interface ITitleBarButton {
  label: string
  icon?: string
  to?: string
  type?: 'is-primary' | 'is-info' | 'is-success'
}

@Component({
  components: {
    DynamicSection,
  },
})
export default class TitleBar extends Vue {
  // Properties
  @Prop({ required: true, type: Array, default: () => [] })
  titleStack!: Array<string>
  @Prop({ type: Object })
  button?: ITitleBarButton
  @Prop({ type: Boolean, default: false })
  goBackBtn!: boolean

  // Computed Data
  get hasButton() {
    return this.button || this.goBackBtn
  }

  // Methods
  onButtonClick() {
    if (this.goBackBtn) this.$router.back()
    else if (this.button?.to) this.$router.push(this.button.to)
    else this.$emit('click')
  }
}
