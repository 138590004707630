






















import dayjs from 'dayjs'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    ValidationProvider,
  },
})
export default class DatePickerWithValidation extends Vue {
  $refs!: {
    hasFocus: HTMLFormElement
  }

  // Properties
  @Prop({ type: Boolean, default: true })
  horizontal!: boolean
  @Prop({ type: String, required: true })
  name!: string
  @Prop({ type: String, required: true })
  label!: string
  @Prop({ type: Date, default: undefined })
  minDate!: Date
  @Prop({ type: Date, default: undefined })
  maxDate!: Date
  @Prop(String)
  value: string | undefined
  @Prop({ type: Boolean, default: false })
  autofocus!: boolean
  @Prop(String)
  message: string | undefined
  @Prop([Object, String])
  rules: Record<string, any> | string | undefined

  // Data
  selectedDate: Date | null = null
  focusable = false

  // Computed Data
  get pLabel() {
    if (
      this.label &&
      ((typeof this.rules === 'string' && this.rules.includes('required')) ||
        (typeof this.rules === 'object' && this.rules['required']))
    ) {
      return this.label + ' *'
    }
    return this.label
  }

  // Vue Hooks
  mounted() {
    if (this.value) this.onValueChange(this.value)
    if (this.autofocus) this.$refs.hasFocus.focus()
  }

  // Watchers
  @Watch('value')
  onValueChange(value: string) {
    if (value != '') {
      this.selectedDate = dayjs(value, 'YYYY-MM-DD').toDate()
    } else {
      this.selectedDate = null
    }
  }
  @Watch('selectedDate')
  onSelectedDateChange(value: Date) {
    if (value) {
      this.$emit('input', dayjs(value).format('YYYY-MM-DD'))
    } else {
      this.$emit('input', '')
    }
  }
}
