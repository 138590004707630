import axios from 'axios'

const autocompleteApi = {
  async getCountries() {
    return axios.get('/data-sources/countries-v1.json')
  },
  async getCities() {
    return axios.get('/data-sources/cities-v1.json')
  },
  async getProvinces() {
    return axios.get('/data-sources/provinces-v1.json')
  },
}

export default autocompleteApi
