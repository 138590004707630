


















import { Vue, Component, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

export interface ISelectOption {
  value: string | number
  tag: string
}

@Component({
  components: {
    ValidationProvider,
  },
})
export default class SelectFieldWithValidation extends Vue {
  $refs!: {
    hasFocus: HTMLFormElement
  }

  // Properties
  @Prop({ type: Boolean, default: true })
  horizontal!: boolean
  @Prop({ type: String, required: true })
  name!: string
  @Prop({ type: String, required: true })
  label!: string
  @Prop({ type: Array, required: true })
  options!: ISelectOption[]
  @Prop({ type: [String, Number] })
  value: string | number | undefined
  @Prop({ type: Boolean, default: false })
  autofocus!: boolean
  @Prop(String)
  message: string | undefined
  @Prop([Object, String])
  rules: Record<string, any> | string | undefined

  // Computed Data
  get pLabel() {
    if (
      this.label &&
      ((typeof this.rules === 'string' && this.rules.includes('required')) ||
        (typeof this.rules === 'object' && this.rules['required']))
    ) {
      return this.label + ' *'
    }
    return this.label
  }
  get boundValue() {
    if (!this.value) return ''
    return this.value
  }
  set boundValue(value) {
    this.$emit('input', value)
  }

  // Vue Hooks
  mounted() {
    if (this.autofocus) this.$refs.hasFocus.focus()
  }
}
